<template>
  <section class="mt93">
    <h2 class="subBanner"><!--img src="@/assets/img/subBanner5.png" alt="" /--></h2>
    <div class="boardwrap">

      <div class="w100w">
        <div class="stribuname"><span>{{userData.memNick}}</span> {{$t('front.stributor.strInfo')}}</div>
        <div class="pagenamew">
          <h3 class="pagename2 w100w">{{$t('front.stributor.strStatus')}}
            <a class="saveBtn mb0" @click="onChangePartnerAddPopup" v-if="userData.partnerLevel !== 'PTN_5'">
              {{$t('front.stributor.bottommake')}}
            </a>
          </h3>
        </div>
        <ul class="strhead">
          <li><img src="@/assets/img/allUserso.png" alt=""><span>{{$t('front.stributor.Alluser')}}</span><span class="strnum">{{thousand((summary  && summary['COUNT(*)']) || 0)}}</span></li>
          <li><img src="@/assets/img/setting_completeo.png" alt=""><span>{{$t('front.stributor.rateComplet')}}</span><span class="strnum">{{thousand( (summary && summary['COUNT(*)'] || 0) - (summary && summary.notSetCnt || 0) )}}</span></li>
          <li><img src="@/assets/img/setting_incompleteo.png" alt=""><span>{{$t('front.stributor.rateIncomplete')}}</span><span class="strnum">{{thousand(summary && summary.notSetCnt || 0)}}</span></li>
          <li><img src="@/assets/img/pointo.png" alt=""><span>{{$t('front.stributor.allPoint')}}</span><span class="strnum">{{thousand(summary && summary.allpoinAmt || 0)}}</span></li>
          <li><img src="@/assets/img/usePointo.png" alt=""><span>{{$t('front.stributor.usePoint')}}</span><span class="strnum">{{thousand(summary && summary.usePoinAmt || 0)}}</span></li>
          <li><img src="@/assets/img/currentPointo.png" alt=""><span>{{$t('front.stributor.myPoint')}}</span><span class="strnum">{{thousand(summary && summary.pointAmt || 0)}}</span></li>
          <li><img src="@/assets/img/progresso.png" alt=""><span>{{$t('front.stributor.progressMoney')}}</span><span class="strnum">{{thousand(summary && summary.waitPoinAmt || 0)}}</span></li>
        </ul>
        <div v-if="make" class="moveWrap">
          <a @click="onChangePartnerAddPopup" class="close"><img src="@/assets/img/icon_cancel.png" /></a>
          <h4>파트너 정보</h4>
          <table>
            <colgroup>
              <col width="20%"/>
              <col width="30%"/>
              <col width="20%"/>
              <col width="30%"/>
            </colgroup>
            <tr>
              <th>아이디</th>
              <td><input class="in" name="newMemId" type="text" v-model="newPartner.memId" autocomplete="off" role="presentation"/></td>
              <th>비밀번호</th>
              <td><input class="in" name="newMemPass" type="password" v-model="newPartner.memPass" autocomplete="off"/></td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td><input class="in" type="text" v-model="newPartner.memNick"/></td>
              <th>출금 비밀번호</th>
              <td><input class="in" type="password" v-model="newPartner.cashOutPass"/></td>
            </tr>
            <tr>
              <th>생성파트너등급</th>
              <td>{{newPartnerLevel.codeName}}</td>
              <th>계좌정보</th>
              <td>
                <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
                <input class="in" type="text" v-model="newPartner.bankAcc"/>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td><input class="in" type="text" v-model="newPartner.memName"/></td>
              <th>전화번호</th>
              <td><input class="in" type="text" v-model="newPartner.memPhone"/></td>
            </tr>
          </table>
          <h4>게임별 요율설정</h4>
          <table>
            <tr>
              <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.roll')}}(%)</th>
              <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.lose')}}(%)</th>
            </tr>
            <tr>
              <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                {{$t(`front.gnb.${key}`)}}
              </th>
              <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                {{$t(`front.gnb.${key}`)}}
              </th>
            </tr>
            <tr>
              <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                <a class="rbnt">최대값 : {{item.maxPointRate}}</a>
                <a class="rbnt">최소값 : {{item.minPointRate}}</a>
              </td>
              <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                <a class="rbnt">최대값 : {{item.maxLoseRate}}</a>
                <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
              </td>
            </tr>
            <tr>
              <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                <input type="text" v-model="item[`${key}PR`]">
              </td>
              <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                <input type="text" v-model="item[`${key}LR`]">
              </td>
            </tr>
          </table>
          <a class="btn" @click="onAddPartnerSubmit">{{$t('front.stributor.save')}}</a>
        </div>

        <div class="pagenamew">
          <h3 class="pagename2">{{$t('front.stributor.totalList')}}</h3>
        </div>
        <div class="datesearch2">
          <date-filter :retail="true" @search="loadMainTable"
                       @update="onChangeDateTable"
                       :defaultDay="0"
                       :startDate="mainTableDate.startDate"
                       :endDate="mainTableDate.endDate"
                       :isOldYn="true"
                       :id="'main-date-checkbox'"
                       @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
          <a class="saveBtn" @click="move=!move">{{$t('front.stributor.move')}}</a>
        </div>
        <ul class="strbtn mb20">
          <li>{{$t('front.common.deposit')}}<span>{{thousand(total.userCashIn || 0)}}</span></li>
          <li>{{$t('front.common.withdrawal')}}<span>{{thousand(total.userCashOut || 0)}}</span></li>
          <li>{{$t('front.common.depositWithdrawal')}}<span>{{thousand(total.userCashResultAmt || 0)}}</span></li>
          <li>{{$t('front.stributor.m22')}}<span>{{thousand(total.betAmt || 0)}}</span></li>
          <li>{{$t('front.stributor.m23')}}<span>{{thousand(total.betAmtWin || 0)}}</span></li>
          <li>{{$t('front.stributor.winlose')}}: <span>{{thousand(total.betResultAmt || 0)}}</span></li>
        </ul>
        <!--retail-rate :rate="rate"></retail-rate-->
        <retail-main-table :list="list" :date="mainTableDate" :table="'main'"/>
        <div v-if="move" class="moveWrap">
          <a @click="move=!move" class="close"><img src="@/assets/img/icon_cancel.png" /></a>
          <h4>내요율</h4>
          <table>
            <tr>
              <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.roll')}}(%)</th>
              <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.lose')}}(%)</th>
            </tr>
            <tr>
              <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
              <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
              <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
              <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
              <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
              <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
            </tr>
            <tr>
              <td v-if="gameCount['casino']">{{myRate.casinoPR}}</td>
              <td v-if="gameCount['slot']">{{myRate.slotPR}}</td>
              <td v-if="gameCount['minigame'] || gameCount['mini game']">{{myRate.miniPR}}</td>
              <td v-if="gameCount['casino']">{{myRate.casinoLR}}</td>
              <td v-if="gameCount['slot']">{{myRate.slotLR}}</td>
              <td v-if="gameCount['minigame'] || gameCount['mini game']">{{myRate.miniLR}}</td>
            </tr>
          </table>
          <h4>하부회원 요율조정</h4>
          <div class="scroll">
            <table>
              <tr>
                <th rowspan="2">{{$t('front.common.memId')}}</th>
                <th rowspan="2">{{$t('front.common.nickName')}}</th>
                <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.roll')}}(%)</th>
                <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.lose')}}(%)</th>
              </tr>
              <tr>
                <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                <th v-if="gameCount['minigame'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
              </tr>
              <template v-if="myChildrenRate.length">
                <template v-for="item in myChildrenRate" :key="item.memId">
                  <tr>
                    <td>{{item.memId}}</td>
                    <td>{{item.memNick}}</td>
                    <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoPRError}" type="text" v-model="item.casinoPR"></td>
                    <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotPRError}" type="text" v-model="item.slotPR"></td>
                    <td v-if="gameCount['minigame']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.miniPRError}" type="text" v-model="item.miniPR"></td>
                    <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoLRError}" type="text" v-model="item.casinoLR"></td>
                    <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotLRError}" type="text" v-model="item.slotLR"></td>
                    <td v-if="gameCount['minigame']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.miniLRError}" type="text" v-model="item.miniLR"></td>
                  </tr>
                </template>
              </template>
            </table>
          </div>
          <p>하부회원의 최대 요율은 내 상위요율을 넘을 수 없습니다. 최소요율은 해당회원의 하부 최대 요율보다 낮을 수 없습니다.</p>
          <a class="btn" @click="onUpdateChildrenRate">{{$t('front.stributor.save')}}</a>
        </div>

        <!--div class="pagenamew">
          <h3 class="pagename2">{{$t('front.stributor.myDetail')}}</h3>
        </div>
        <div class="datesearch2">
          <date-filter :retail="true" @search="loadList"
                       @update="onChangeDateInfo"
                       :defaultDay="0"
                       :startDate="detailInfoDate.startDate"
                       :endDate="detailInfoDate.endDate" />
        </div-->

        <retail-detail :className="'mb60'" :list="detailList"></retail-detail>

<!--        <div class="pagenamew">-->
<!--          <h3 class="pagename2">게임별 베팅 합계 (하위 포함)</h3>-->
<!--        </div>-->
<!--        <div class="datesearch2">-->
<!--          <date-filter :retail="true" @search="loadList"-->
<!--                       @update="onChangeDate"-->
<!--                       :startDate="searchDate.startDate"-->
<!--                       :endDate="searchDate.endDate" />-->
<!--        </div>-->
<!--        <table class="strTable mb60">-->
<!--          <colgroup>-->
<!--            <col width="10%">-->
<!--            <col width="13%">-->
<!--            <col width="11%">-->
<!--            <col width="11%">-->
<!--            <col width="11%">-->
<!--            <col width="11%">-->
<!--            <col width="11%">-->
<!--          </colgroup>-->
<!--          <tr>-->
<!--            <th>세부</th>-->
<!--            <th>게임</th>-->
<!--            <th>총베팅</th>-->
<!--            <th>총당첨</th>-->
<!--            <th>베팅손익</th>-->
<!--            <th>하위 롤링P</th>-->
<!--            <th>내 롤링P</th>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td @click="moret=!moret">-->
<!--              <a class="moreBtn b">▾▴</a>-->
<!--            </td>-->
<!--            <td>라이브카지노</td>-->
<!--            <td>151,585,568</td>-->
<!--            <td>129,802,962</td>-->
<!--            <td>21,782,606</td>-->
<!--            <td>1,645,441</td>-->
<!--            <td>1,645,441</td>-->
<!--          </tr>-->
<!--          <tr v-if="moret">-->
<!--            <td colspan="9">-->
<!--              <table class="strTable more">-->
<!--                <colgroup>-->
<!--                  <col width="10%">-->
<!--                  <col width="13%">-->
<!--                  <col width="11%">-->
<!--                  <col width="11%">-->
<!--                  <col width="11%">-->
<!--                  <col width="11%">-->
<!--                  <col width="11%">-->
<!--                </colgroup>-->
<!--                <tr>-->
<!--                  <th>세부</th>-->
<!--                  <th>게임</th>-->
<!--                  <th>총베팅</th>-->
<!--                  <th>총당첨</th>-->
<!--                  <th>베팅손익</th>-->
<!--                  <th>하위 롤링P</th>-->
<!--                  <th>내 롤링P</th>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>-->
<!--                    <a class="moreBtn b">▾▴</a>-->
<!--                  </td>-->
<!--                  <td>게임1</td>-->
<!--                  <td>151,585,568</td>-->
<!--                  <td>129,802,962</td>-->
<!--                  <td>21,782,606</td>-->
<!--                  <td>1,645,441</td>-->
<!--                  <td>1,645,441</td>-->
<!--                </tr>-->
<!--              </table>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>-->
<!--              <a class="moreBtn b">▾▴</a>-->
<!--            </td>-->
<!--            <td>호텔카지노</td>-->
<!--            <td>151,585,568</td>-->
<!--            <td>129,802,962</td>-->
<!--            <td>21,782,606</td>-->
<!--            <td>1,645,441</td>-->
<!--            <td>1,645,441</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>-->
<!--              <a class="moreBtn b">▾▴</a>-->
<!--            </td>-->
<!--            <td>슬롯</td>-->
<!--            <td>151,585,568</td>-->
<!--            <td>129,802,962</td>-->
<!--            <td>21,782,606</td>-->
<!--            <td>1,645,441</td>-->
<!--            <td>1,645,441</td>-->
<!--          </tr>-->
<!--          <tr class="total">-->
<!--            <td></td>-->
<!--            <td>합</td>-->
<!--            <td>151,585,568</td>-->
<!--            <td>129,802,962</td>-->
<!--            <td>21,782,606</td>-->
<!--            <td>1,645,441</td>-->
<!--            <td>1,645,441</td>-->
<!--          </tr>-->
<!--        </table>-->
      </div>
    </div>
  </section>
</template>

<script>
import {
  getRetailMyInfo,
  getRetailSummary, partnerJoin, partnerLevels, partnerRates, retailMemRate
  , retailMyCalculate, retailUpdate
} from '@/api/retail'
import DateFilter from '@/components/ui/DateFilter'
import RetailDetail from '@/components/common/retailDetail'
import RetailMainTable from '@/components/member/stributor/RetailMainTable.vue'
import { getDateStr } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import BankList from '@/components/ui/BankList.vue'
export default {
  name: 'stributorPage',
  components: { BankList, RetailMainTable, RetailDetail, DateFilter },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      summary: {},
      rate: {},
      detailInfo: {},
      detailList: {},
      loginMemList: [],
      memList: [],
      memberDetailList: {},
      memberRate: {},
      isMemberDetail: false,
      isMemberRate: false,
      moret: false,
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      detailInfoDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        searchType: 'OUTER',
        chkTodayYn: 'N',
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N'
      },
      list: [],
      move: false,
      make: false,
      myRate: {},
      myChildrenRate: [],
      newPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      defaultNewPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      rateMaxMinList: [],
      newPartnerLevel: {},
      total: {}
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))

    await this.loadMainTable()
    this.getSummary()
    await this.getMyInfo()
    this.onLoadRate()
    await this.getPartnerRateForNewPartner()
    this.emitter.emit('Loading', false)
  },
  methods: {
    setOldYn (data) {
      console.log('table : ', data)
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.newPartnerLevel = result.data.list[0]
        }
      })
    },
    onChangePartnerAddPopup () {
      this.make = !this.make
      if (this.make) {
        const list = this.rateMaxMinList
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const vendorCode = item.vendorCode
          let groupCode = this.commonCodeByCode[vendorCode].groupCode
          if (groupCode === 'minigame') {
            groupCode = 'mini'
          }
          if (groupCode) {
            this.newPartner.rateGroup[groupCode] = {
              ...item
            }
            this.newPartner.rateGroup[groupCode][`${groupCode}PR`] = '0.0'
            this.newPartner.rateGroup[groupCode][`${groupCode}LR`] = '0.0'
          }
        }
        console.log(this.newPartner.rateGroup)
      }
    },
    getPartnerRateForNewPartner () {
      return partnerRates({}).then(res => {
        console.log('[partnerRates] : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.rateMaxMinList
          this.rateMaxMinList = list
        }
      })
    },
    onAddPartnerSubmit () {
      console.log(this.newPartner)
      const reqData = { ...this.newPartner, rateGroup: {} }

      for (const groupCode in this.newPartner.rateGroup) {
        const item = this.newPartner.rateGroup[groupCode]
        const pr = item[`${groupCode}PR`]
        if (pr) {
          if (Number(item.maxPointRate) < Number(pr) || Number(item.minPointRate) > Number(pr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
          return false
        }
        const lr = item[`${groupCode}LR`]
        if (lr) {
          if (Number(item.maxLoseRate) < Number(lr) || Number(item.minLoseRate) > Number(lr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
          return false
        }

        reqData.rateGroup[`${groupCode}PR`] = pr
        reqData.rateGroup[`${groupCode}LR`] = lr
      }

      if (!reqData.memId) {
        this.onAlert('warningart', 'front.member.emptyMemId')
        return false
      }
      if (!reqData.memPass) {
        this.onAlert('warningart', 'front.member.emptyMemPass')
        return false
      }
      if (!reqData.memNick) {
        this.onAlert('warningart', 'front.member.emptyMemNick')
        return false
      }
      if (!reqData.cashOutPass) {
        this.onAlert('warningart', 'front.member.emptyCashOutPass')
        return false
      }
      if (!reqData.bank) {
        this.onAlert('warningart', 'front.member.emptyBankName')
        return false
      }
      if (!reqData.bankAcc) {
        this.onAlert('warningart', 'front.member.emptyBankNumber')
        return false
      }
      if (!reqData.memName) {
        this.onAlert('warningart', 'front.member.emptyMemName')
        return false
      }
      if (!reqData.memPhone) {
        this.onAlert('warningart', 'front.member.emptyMemTel')
        return false
      }

      console.log(reqData)

      partnerJoin(reqData).then(res => {
        console.log(res)
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('하위파트너가 정상적으로 생성되었습니다.')
          this.onChangePartnerAddPopup()
          this.newPartner = { ...this.defaultNewPartner }
        } else {
          this.onAlert('warningart', '생성에 실패하였습니다. 다시 시도해주세요')
        }
      })
    },
    onChangeBank (value) {
      this.newPartner.bank = value
    },
    async onUpdateChildrenRate () {
      const rateList = this.myChildrenRate

      for (let i = 0, iLen = rateList.length; i < iLen; i++) {
        const item = rateList[i]
        if (item.casinoLRError ||
            item.casinoPRError ||
            item.slotLRError ||
            item.slotPRError ||
            item.miniLRError ||
            item.miniPRError
        ) {
          return
        }
      }

      const confirm = await this.onConfirm('front.common.confirmSave')
      if (confirm) {
        console.log(rateList)
        const param = {
          siteId: '',
          memId: '',
          rateList: rateList
        }

        retailUpdate(param).then(res => {
          const data = res.data
          if (data.resultCode === '0') {
            this.onCheck('front.recommender.complete')
            this.onLoadRate()
          }
        })
      }
    },
    onChangeChildrenRate (item) {
      console.log(item)

      const casinoPR = Number(item.casinoPR)
      if (casinoPR || casinoPR === 0) {
        const maxCasinoPR = Number(item.maxCasinoPR)
        const minCasinoPR = Number(item.minCasinoPR)
        if (maxCasinoPR < casinoPR || minCasinoPR > casinoPR) {
          item.casinoPRError = true
        } else {
          item.casinoPRError = false
        }
      }

      const slotPR = Number(item.slotPR)
      if (slotPR || slotPR === 0) {
        const maxSlotPR = Number(item.maxSlotPR)
        const minSlotPR = Number(item.minSlotPR)
        if (maxSlotPR < slotPR || minSlotPR > slotPR) {
          item.slotPRError = true
        } else {
          item.slotPRError = false
        }
      }

      const miniPR = Number(item.miniPR)
      if (miniPR || miniPR === 0) {
        const maxMiniPR = Number(item.maxMiniPR)
        const minMiniPR = Number(item.minMiniPR)
        if (maxMiniPR < miniPR || minMiniPR > miniPR) {
          item.miniPRError = true
        } else {
          item.miniPRError = false
        }
      }

      const casinoLR = Number(item.casinoLR)
      if (casinoLR || casinoLR === 0) {
        const maxCasinoLR = Number(item.maxCasinoLR)
        const minCasinoLR = Number(item.minCasinoLR)
        if (maxCasinoLR < casinoLR || minCasinoLR > casinoLR) {
          item.casinoLRError = true
        } else {
          item.casinoLRError = false
        }
      }

      const slotLR = Number(item.slotLR)
      if (slotLR || slotLR === 0) {
        const maxSlotLR = Number(item.maxSlotLR)
        const minSlotLR = Number(item.minSlotLR)
        if (maxSlotLR < slotLR || minSlotLR > slotLR) {
          item.slotLRError = true
        } else {
          item.slotLRError = false
        }
      }

      const miniLR = Number(item.miniLR)
      if (miniLR || miniLR === 0) {
        const maxMiniLR = Number(item.maxMiniLR)
        const minMiniLR = Number(item.minMiniLR)
        if (maxMiniLR < miniLR || minMiniLR > miniLR) {
          item.miniLRError = true
        } else {
          item.miniLRError = false
        }
      }
    },
    onLoadRate () {
      retailMemRate({}).then(res => {
        const data = res.data
        if (data.resultCode === '0') {
          console.log(data)
          this.myRate = data.data.myCategoryRate
          this.myChildrenRate = data.data.botCategoryRateList
        }
      })
    },
    onChangeDateTable (value) {
      console.log(value)
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    onChangeDateInfo (value) {
      console.log(value)
      this.detailInfoDate.startDate = getDateStr(new Date(value.startDate))
      this.detailInfoDate.endDate = getDateStr(new Date(value.endDate))
    },
    async loadMainTable (value) {
      if (!value) {
        this.emitter.emit('Loading', true)
        const params = {
          ...this.reqData
        }
        console.log(params)

        this.mainTableDate.startDate = this.reqData.startDate
        this.mainTableDate.endDate = this.reqData.endDate

        const today = new Date()
        if (params.endDate === getDateStr(today)) {
          params.chkTodayYn = 'Y'
        } else {
          params.chkTodayYn = 'N'
        }

        console.log('[req][retailMyCalculate] : ', params)
        await retailMyCalculate(params).then(res => {
          console.log('[res][retailMyCalculate] : ', res)
          const data = res.data
          if (data.resultCode === '0') {
            this.list = data.data.outSearchList
            this.total = data.data.outSearchTotal
          }

          this.emitter.emit('Loading', false)
        })
      }
    },
    loadList () {
      this.getMyInfo()
    },
    getSummary () {
      getRetailSummary({}).then(response => {
        const data = response.data
        if (data.resultCode === '0') {
          this.summary = data.data.summary
        }
      })
    },
    async getMyInfo () {
      this.emitter.emit('Loading', true)
      getRetailMyInfo(this.detailInfoDate).then(async response => {
        const data = response.data
        if (data.resultCode === '0') {
          console.log(data)
          this.detailInfo = data.data.mycashinfo
          this.detailList = {}
          console.log(data.data.myBetPointinfo)
          data.data.myBetPointinfo.forEach(item => {
            const groupCode = item.groupCode || 'mg'
            const gameType = item.gameType
            if (groupCode) {
              if (!this.detailList[groupCode]) {
                this.detailList[groupCode] = []
              }

              if (this.commonCodeByCode[gameType]) {
                this.detailList[groupCode].push(item)
              }
            }
          })
        }
        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>
<style>
.blc {color: #0f84ee;font-weight: bold;}
.rdc {color: #e1534e;font-weight: bold;}
.strTable.more {/*border: 2px solid #e5972d;*/width: 100%;}
.strTable.more .strTable.more {/*border: 0;*/}

/*.strTable.more td {background: #fff3f3 !important;}
.strTable.more .strTable.more td {background: #e8ffe9 !important;}
.strTable.more .strTable.more .strTable.more td {background: #d8eaf5 !important;}
.strTable.more .strTable.more .strTable.more .strTable.more td {background: #fffee1 !important;}
.strTable.more .strTable.more .strTable.more .strTable.more .strTable.more td {background: #e1fffb !important;}
.strTable.more .strTable.more .strTable.more .strTable.more .strTable.more .strTable.more td {background: #ffe1e1 !important;}*/

.moreT img {margin-right: 6px;}
.strTable {width: 100%;text-align: center;vertical-align: middle;font-size: 14px;
  overflow: hidden;border-collapse: collapse;border-spacing: 0;}
.strTable th {height: 40px;background: #555;color: #fff;vertical-align: middle;}
.strTable td {height: 35px;background: #222;vertical-align: middle;border-bottom: solid 1px #333;border-right: 1px solid #333;white-space: nowrap;color: #fff;overflow: hidden;text-overflow: ellipsis;position: relative;}
.strTable td:last-child {border-right: 0;}
.money {text-align: left;}
.money li {padding: 0 10px;height: 30px;display: flex;align-items: center;justify-content: space-between;}
.money li:nth-child(4) {background: #141414;}
.moreBtn {box-sizing: border-box;display: flex;align-items: center;justify-content: center;font-weight: bold;color: #c3c3c3;position: absolute;bottom: 0;right: 0;width: 30px;height: 30px;background: #333;cursor: pointer;transition: all 0.7s ease;font-size: 25px;}
.moreBtn img {height: 22px;transform: rotate(90deg);transition: all 0.7s ease;}
.moreBtn .plus img {transform: rotate(-90deg);}
/*.moreBtn .plus, .moreBtn .x {width: 20px;height: 20px;}
.moreBtn .plus:before,
.moreBtn .plus:after,
.moreBtn .x:before,
.moreBtn .x:after {position: absolute;right: 5px;content:' ';height: 20px;width: 2px;background-color: #fff;}
.moreBtn .plus:before {transform: rotate(45deg);}
.moreBtn .plus:after {transform: rotate(-45deg);}
.moreBtn .x:before {transform: rotate(0deg);}
.moreBtn .x:after {transform: rotate(-90deg);}*/
.name {padding-left: 20px;text-align: left;display: block;}
.name span {display: block;font-size: 12px;color: #eee;margin-bottom: 5px;}
.name em {display: block;color: #e5972d;margin-top: 5px;}
.lRoll span:first-child {margin-bottom: 6px;}
.lRoll span {display: block;text-align: left;padding-left: 5px;}
.lRoll input {width: 45px;margin: 0 3px;}
.userBetBtn {border: 1px solid #141414;background: #555;border-radius: 5px;font-size: 12px;padding: 6px 8px;display: inline-block;cursor: pointer;position: absolute;right: 5px;top: 10px;}
.userBetWrap {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);padding: 0 0 50px;border: 2px solid #333;background: #141414;z-index: 100;width: 100%;max-width: 1200px;max-height: 820px;height: 100%;overflow: auto;}
.userBetWrap .close {cursor: pointer;display: inline-block}
.userBetWrap .close img {height: 35px;}
.userBetWrap h4 {font-size: 20px;font-weight: bold;margin: 40px 25px 25px;position: relative;color: #fff;display: flex;align-items: center;justify-content: space-between;}
.userBetWrap .tab-content {max-width: 1600px;width: calc(100% - 25px);margin: 0 0 30px 25px;}
.userBetWrap > div {white-space:nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align:center;}
.userBetWrap > div::-webkit-scrollbar {display: none;}
.userBetWrap .board {width: 1150px;}
.gameName {background: #141414;border-radius: 5px;border: 1px solid #333;color: #fff;margin: 0 25px 28px;height: 30px;padding: 0 10px;display: flex;justify-content: left;}

</style>
<style scoped>
.w100w {margin: 0 20px auto;min-width: 1314px;}
.nonebtn {background: none !important;border: 0 !important;width: auto !important;}
.rollname {font-size: 16px;color: #000;margin-bottom: 18px;}
.close {position: absolute;right: 15px;top: 15px;}
.board {box-shadow: 0 0px 6px 0 rgb(0 0 0 / 20%);border-radius: 0 0 10px 10px;}
.boardstrin .rollname {margin-top: 30px;}
.board.even .boardstr {background: #f8f9fb;}
.datesearch2 {display: flex;justify-content: space-between;align-items: center;}
.datesearch2 .btn {background: #fc7220;font-size: 14px;width: 93px;height: 28px;color: #fff;line-height: 28px;text-align: center;border-radius: 5px;margin-bottom: 18px;}

.saveBtn {background: #141414;border: 1px solid #333;font-size: 14px;padding: 0 10px;height: 28px;color: #fff;line-height: 28px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;margin-left: auto;}

.moveWrap {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;padding: 20px 50px;border: 2px solid #333;background :#141414;z-index: 1;}
.moveWrap .close {position: absolute;right: 20px;top: 20px;}
.moveWrap .close img {height: 35px;}
.moveWrap h4 {font-size: 20px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #fff;}
.moveWrap table {width: 800px;text-align: center;}
.moveWrap th {height: 40px;background: #555;border: solid 1px #333;color: #fff;vertical-align: middle;}
.moveWrap td {height: 40px;background: #141414;vertical-align: middle;border: solid 1px #333;white-space: nowrap;color: #fff;overflow: hidden;text-overflow: ellipsis;position: relative;}
.moveWrap input {width: 50px;border: 1px solid #333;background: #141414;border-radius: 3px;color: #fff;}
.moveWrap input.in {width: 150px;height: 28px;}
.moveWrap select.in {width: 80px;height: 28px;border: 1px solid #333;background: #141414;color: #fff;margin-right: 5px;}
.moveWrap p {margin-top: 25px;color: #e5972d;font-size: 12px;text-align: right;}
.moveWrap a.btn {margin-top: 25px;background: #e5972d;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
.rbnt {display: block;margin-bottom: 10px;}
.rbnt:first-child {margin-top: 10px;}
.error {border-color: #ff3f45 !important;}
.scroll {max-height: 400px;overflow-y: auto;}

.strTable {width: 100%;text-align: center;vertical-align: middle;font-size: 14px;overflow: hidden;border-collapse: collapse;border-spacing: 0;}
.strTable th {height: 40px;background: #555;color: #fff;vertical-align: middle;}
.strTable td {height: 35px;background: #222;vertical-align: middle;border-bottom: solid 1px #333;border-right: 1px solid #333;white-space: nowrap;color: #fff;overflow: hidden;text-overflow: ellipsis;position: relative;}
.strTable td:last-child {border-right: 0;}
.total td {background: #141414;}
.moreBtn.b {position: relative;border: solid 1px #fff;width: 50px;margin: 0 auto;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
